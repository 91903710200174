<template>
    <v-card>
        <v-card-title class="ml-3">Cadastro de usuários</v-card-title>
        <v-divider></v-divider>
        <v-form class="ma-3" ref="form" v-model="valid" lazy-validation>
            <v-card-text>
                <v-row>
                    <v-col cols="12" lg="6" class="mb-n5">
                        <v-text-field :disabled="carregando" v-model="nome" label="* Nome completo" :rules="nomeRegra" required />
                    </v-col>
                    <v-col cols="12" lg="6" class="mb-n5">
                        <v-text-field v-mask="'###.###.###-##'" :disabled="carregando" v-model="cpf" label="* CPF" :rules="cpfRegra" required />
                    </v-col>
                    <v-col cols="12" lg="6" class="mb-n5">
                        <v-text-field :disabled="carregando" v-model="usuariociss" label="* Usuário CISS" :rules="usuariocissRegra" required />
                    </v-col>
                    <v-col cols="12" lg="6" class="mb-n5">
                        <v-text-field :disabled="carregando" v-model="usuario" label="* Usuário" :rules="usuarioRegra" required />
                    </v-col>
                    <v-col cols="12" lg="6" class="mb-n5">
                        <v-text-field :disabled="carregando" v-model="email" label="E-mail" :rules="emailRegra" required />
                    </v-col>
                    <!--<v-col cols="12" lg="4" class="mb-n5">
                        <v-select
                            :items="filiais.lista"
                            item-value="idfilial"
                            item-text="filial"
                            label="* Filial"
                            v-model="idfilial"
                            :rules="[v => !!v || 'Campo obrigatório']"
                            :disabled="carregando"
                        ></v-select>
                    </v-col>-->
                    <v-col cols="12" lg="6" class="mb-n5">
                        <v-select
                            :items="grupos.lista"
                            item-value="idgrupo"
                            item-text="grupo"
                            label="* Grupo"
                            v-model="idgrupo"
                            :rules="[v => !!v || 'Campo obrigatório']"
                            :disabled="carregando"
                        ></v-select>
                    </v-col>
                </v-row>    
                <v-alert v-if="erro" dismissible icon="mdi-alert-circle-outline" close-icon="mdi-close" type="error" class="mb-0 mt-6" v-html="erro"></v-alert>
            </v-card-text>
            <v-card-actions>
                <v-spacer/>
                <v-btn :disabled="carregando" color="primary" class="mr-2 mb-4 px-5" @click="cadastrar">Cadastrar</v-btn>
            </v-card-actions>
        </v-form>
    </v-card>
</template>

<script>
import axios from 'axios'
import {mapState} from 'vuex'

export default {
    name : "UsuarioCadastro",
    data : () => ({
        carregando       : false,
        valid            : true,
        cpf              : '',
        cpfRegra         : [v => !!v || 'Campo obrigatório'],
        usuario          : '',
        usuarioRegra     : [v => !!v || 'Campo obrigatório'],
        usuariociss      : '',
        usuariocissRegra : [v => !!v || 'Campo obrigatório'],
        nome             : '',
        nomeRegra        : [v => !!v || 'Campo obrigatório'],
        email            : '',
        emailRegra       : [v => !!v || 'Campo obrigatório'],
        filiais         : {},
        // idfilial         : null,
        idgrupo          : null,
        grupos           : {},
        erro             : ''
    }),
    computed : {
        ...mapState(['backendUrl'])
    },
    methods : {
        validate() {
            return this.$refs.form.validate()
        },
        /*listarEmpresas() {
            return axios.post(`${this.backendUrl}filial/listar`, {
                limit    : 9999,
                offset   : 0,
                busca    : null
            }).then( (res) => {
                this.filiais = res.data
            })
        },*/
        listarGrupos() {
            return axios.post(`${this.backendUrl}usuario/grupo/listar`, {
                limit    : 9999,
                offset   : 0,
                busca    : null
            }).then( (res) => {
                this.grupos = {
                    lista : res.data.lista.filter(v => v.idgrupo != 3), // Não exibe o grupo "Clientes", esse tipo de usuário só deve ser cadastrado na página do cliente
                    total : res.data.total,
                }
            })
        },
        cadastrar() {
            //if(this.validate()) {
                return axios.post(`${this.backendUrl}usuario/cadastrausuariogestor`, {
                    cpf         : this.cpf.replaceAll('.', '').replaceAll('-', ''),
                    usuario     : this.usuario,
                    usuariociss : this.usuariociss,
                    nome        : this.nome,
                    email       : this.email,
                    idfilial    : this.idfilial,
                    idgrupo     : this.idgrupo,
                }).then( () => {
                    this.cpf         =
                    this.usuario     =
                    this.usuariociss =
                    this.nome        =
                    this.email       =
                    // this.idfilial =
                    this.idgrupo     = null

                    this.$router.push('/usuario')
                }).catch(e => {
                    this.erro = this.erroFormatar(e)
                })
           // }
        },
    },
    created() {
        //this.listarEmpresas()
        this.listarGrupos()
    }
}
</script>
